export const ClientUIFeatureFlagsFragment_query_FeatureFlags = {
   ENABLE_MESSAGE_EXPLORER_TOOL_UI: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_GRAPHQL_MESSAGE_EXPLORER: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_SUBSCRIBER_AUDIT: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_FLORIDA_SUBSCRIBERS_AUDIT: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_LITIGATOR_OPT_OUT: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_ONBOARDING_FLOW_UI: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_ADMIN_CONCIERGE_AGENTS: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_NOTIFICATION_CONTENT_MANAGEMENT: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_RECORDING_SENDGRID_EMAIL_ACTIVITY: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_DATADOG_SESSION_REPLAY: false,  // defined in ClientUIFeatureFlagsFragment_query
   UI_INTEGRATIONS: false,  // defined in ClientUIFeatureFlagsFragment_query
   SELF_SERVE_ONBOARDING: false,  // defined in ClientUIFeatureFlagsFragment_query
   ONBOARDING_PHASE_IV_BRAND_STYLES: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_BRAND_KIT: false,  // defined in ClientUIFeatureFlagsFragment_query
   CONVERSATIONS: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CONCIERGE_TEMPLATES_ROUTE: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CONCIERGE_CHAT_CONCURRENCY: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CONCIERGE_SPECIALIZATIONS_TAB: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CONVERSATIONS_GRAPHQL: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_JOURNEYS_CONCIERGE: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CONVERSATIONS_NEW_PREVIEWS: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_INTEGRATION_ERRORS: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_CAMPAIGN_EMAIL: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_GAME_TIME_NAV: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_ZENDESK_HELP_CENTER_SEARCH_BAR: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_NEW_HOME_PAGE_DESIGN: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_HELP_LIVE_CHAT_CONSOLIDATION: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: false,  // defined in ClientUIFeatureFlagsFragment_query
   DISABLE_REPORTING_AI_PRO_BUNDLE: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_REPORTING_AI_PRO_BUNDLE: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_AI_PRO_AGGREGATE_REPORTING: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_INTEGRATION_MANAGEMENT: false,  // defined in ClientUIFeatureFlagsFragment_query
   UI_LOW_DATA_MODE: false,  // defined in ClientUIFeatureFlagsFragment_query
   SHOW_SLOW_LOAD_TIMES_DIALOG_CLIENT_UI: false,  // defined in ClientUIFeatureFlagsFragment_query
   SHOW_SYSTEM_STATUS_MESSAGE: false,  // defined in ClientUIFeatureFlagsFragment_query
   SHOW_PLATFORM_UNAVAILABLE_PAGE_CLIENT_UI: false,  // defined in ClientUIFeatureFlagsFragment_query
   SHOW_ACCOUNT_PROFILE_PAGE: false,  // defined in ClientUIFeatureFlagsFragment_query
   REQUIRE_WEBAUTHN_FOR_ADMIN_ACCESS: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_GRAPH_GURU: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_ZENDESK_CHAT_DEVELOPER: false,  // defined in ClientUIFeatureFlagsFragment_query
   ENABLE_ZENDESK_MESSAGING: false,  // defined in ClientUIFeatureFlagsFragment_query
   // ENABLE_SUBSCRIBER_AUDIT: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   // ENABLE_LITIGATOR_OPT_OUT: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   // ENABLE_NOTIFICATION_CONTENT_MANAGEMENT: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_EDIT_PAUSED_CAMPAIGNS: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_EDIT_PAUSED_EXPERIMENTS: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_ERROR_CODE_FILTERING: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_NINE_REGION_EXPANSION: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   // ENABLE_FLORIDA_SUBSCRIBERS_AUDIT: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_UI: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_TOOLS: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   // ENABLE_INTEGRATION_MANAGEMENT: false,  // defined in AdminCenterUIFeatureFlagsFragment_company
   ENABLE_TRIGGERED_EMAIL: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   HIDE_ANALYTICS_BILLABLE_SPEND: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   UI_SHOW_REPORT_DELAY: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   HIDE_TOTAL_ACTIVE_SUBSCRIBERS_AGGREGATE_VALUE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   HIDE_ELIGIBLE_SUBSCRIPTION_COUNT_AGGREGATE_VALUE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_ON_ANALYTICS_DASHBOARD: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   // ENABLE_CAMPAIGN_EMAIL: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_ANALYTICS_UI_DEGRADED_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_PROGRAM_PERFORMANCE_REPORT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_REPORTS_UPDATE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_LEGACY_SUBSCRIBER_REPORTS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_MESSAGE_ID_IN_CAMPAIGN_REPORTS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_GENERATED_REPORTS_RECIPIENT_SELECT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORT_TOUR_MENU_OPTION: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_ANALYTICS_DASHBOARD_RANGE_COMPARISON_SELECTOR: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   BFCM_DATE_RANGE_LOAD_SWITCH_LAST_SEVEN_DAYS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_SLE_MILESTONE_TWO: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_METRICS_API_POINT_IN_TIME_METRICS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   // ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   // ENABLE_REPORTING_AI_PRO_BUNDLE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_HIDING_SUMMARY_METRICS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   // DISABLE_REPORTING_AI_PRO_BUNDLE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   DISABLE_DEVICE_BASED_EVENT_MATCHING: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTING_CUSTOM_CHARTS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_SFTP_VISUAL_REPORT_DELIVERY: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTING_REGION_CURRENCY_OVERRIDE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_REPORT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_CLIENT_FACING_AI_JOURNEYS_REPORT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   // ENABLE_AI_PRO_AGGREGATE_REPORTING: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_ANALYTICS_FF_STATUS_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORT_PAGE_FF_STATUS_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_GENERATED_REPORTS_PAGE_FF_STATUS_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_ANALYTICS_DASHBOARD_FF_STATUS_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_DASHBOARD_FF_STATUS_BANNER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORT_PAGE_STICKY_TABLE: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_DASHBOARD_MAXIMIZE_PERFORMANCE_LINK: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_USE_DASHBOARD_TEXT_REVENUE_LABELS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTING_TOOLBAR_REGION_FILTER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTS_LIBRARY_SEGMENTED_REPORTS: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_IDENTITY_AI_NEW_SUBSCRIBER_PERF_REPORT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTING_IDENTITY_ABANDONMENT_FILTER: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_EAT_AI_PRO_DASHBOARD_REVAMP: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_IDENTITY_AI_ABANDONMENT_PERF_REPORT: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_REPORTING_LIBRARY_AND_SAVED_REPORTS_SEARCH: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_EXPORT_REPORTS_UI: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_COST: false,  // defined in AnalyticsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ESCALATIONS_TABLE: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ESCALATIONS_TABLE_SORT: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_MACRO_LINK: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   DISABLE_CONCIERGE_USER_PROFILE: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   CONCIERGE_PROFILE_CHECKOUT_STARTED: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_INFINITE_SCROLL: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   CONCIERGE_PROFILE_ORDER_HISTORY: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_DETAILED_ORDER_HISTORY: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_CONVERSATION_STATUS_BANNER_ESCALATION_NUDGE: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_BRAND_VOICE: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ML_RECOMMENDED_TEMPLATES: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_PRODUCT_RECS: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_PRODUCT_RECS_AB: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_AGENT_CHECKOUT_LINKS: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_AGENT_CHECKOUT_LINKS_BETA: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_FULL_TEMPLATE_VIEW: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_STREAMLINE_USER_PROFILE: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   // ENABLE_CONCIERGE_CHAT_CONCURRENCY: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_CHAT_CONCURRENCY_REDIRECT: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_COMPANY_PROMOTIONS: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_AGENT_TEMPLATE_ENHANCED_SHORTCUTS: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   // ENABLE_CONCIERGE_SPECIALIZATIONS_TAB: false,  // defined in ConciergeUIFeatureFlagsFragment_company
   ENABLE_ONE_DAY_MESSAGE_HISTORY_CONV_UI: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONVERSATION_MANAGER: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONVO_BRAND_VOICE: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ANALYTICS: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ONBOARDING: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONVERSATIONS_SUBNAV: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_SELF_SERVE_LAUNCH: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_CONCIERGE_CONTROLS: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_HOME_PAGE: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   // ENABLE_CONCIERGE_COMPANY_PROMOTIONS: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_INSIGHTS: false,  // defined in ConversationsUIFeatureFlagsFragment_company
   ENABLE_GLOBAL_NOTIFICATIONS_UI: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_HOME_UI_NOTIFICATION_SUMMARY: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_BFCM_HOLIDAY_CREATIVE_TEMPLATE: false,  // defined in HomeUIFeatureFlagsFragment_company
   DISABLE_HOLIDAY_SUBSCRIBER_SEGMENT_CSM_REC: false,  // defined in HomeUIFeatureFlagsFragment_company
   DISABLE_JOURNEYS_PRICE_DROP_CSM_REC: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_PRICE_DROP: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_AI_COPY_ASSISTANT_HOME_PAGE: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_CA_SHUT_OFF_SWITCH: false,  // defined in HomeUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_HOMEPAGE_STATS: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_UI: false,  // defined in HomeUIFeatureFlagsFragment_company
   UP_REVENUE_MODEL_CANDIDATE: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_SHOPIFY_ATTRIBUTION_OPT_IN_FLOW: false,  // defined in HomeUIFeatureFlagsFragment_company
   ENABLE_NEW_AFTERSHIP_SETUP: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_LISTING_CONTACT_FORM: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_MARKETPLACE_ADMIN_PAGE: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_JOURNEY_SETUP: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_JOURNEY_CREATION: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_PARTNERSHIP_TIERS: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_HEALTH_TAB: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_PUBLISH_APP_RICH_TEXT_EDITOR: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   ENABLE_APP_PERF_REQUEST_TAB: false,  // defined in IntegrationsUIFeatureFlagsFragment_company
   OVERRIDE_TEXTED_A_KEYWORD_JOURNEYS_LIMIT: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_EMAIL_SUBDOMAINS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   // ENABLE_JOURNEYS_PRICE_DROP: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_PRODUCT_DATA: false,  // defined in JourneysUIFeatureFlagsFragment_company
   // ENABLE_JOURNEYS_CONCIERGE: false,  // defined in JourneysUIFeatureFlagsFragment_company
   // ENABLE_JOURNEYS_CONCIERGE_CONTROLS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_PICK_A_WINNER: false,  // defined in JourneysUIFeatureFlagsFragment_company
   JOURNEYS_CLOSE_PROXIMITY_WARNING_BANNER: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_CARRIER_RESTRICTIONS_VALIDATION: false,  // defined in JourneysUIFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_WELCOME_JOURNEY_SIGNUP_PAUSING: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_VERSION_MANAGEMENT: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_JOINED_A_SEGMENT: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_GQL_JOURNEY_TRIGGERS_DATA: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_MAP_HEADER_GQL: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_ENTRY_FREQUENCY_EDITING_FOR_TRANSACTIONAL_JOURNEYS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEY_BUILDER_FORMS_GQL: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_TRANSACTIONAL_JOURNEY_SHOPIFY_PLUS_SEND_EMAIL_VALIDATION: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ONBOARDING_REGION_MGMT: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_SKIP_FETCHING_STATS_FOR_LIST_PAGE: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_WELCOME_MESSAGE_MMS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_SHOPIFY_AUTO_GEN_FOR_WINBACK_JOURNEY: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEY_PREVIEW: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_JOURNEY_SCHEDULER: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_TRANSACTIONAL_JOURNEY_APPROVAL_BYPASS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_TRANSACTIONAL_PRODUCT_RECS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_DYNAMIC_PRODUCTS_FOR_NON_SHOPIFY_CLIENTS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_EMAIL_ONLY_JOURNEYS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_GENERATIVE_EMAIL_DEFAULT_TEMPLATE: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_BACK_IN_STOCK_INVENTORY_THRESHOLDS: false,  // defined in JourneysUIFeatureFlagsFragment_company
   ENABLE_ATT_STOP_LANGUAGE_FE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   // ENABLE_CAMPAIGN_EMAIL: false,  // defined in MessagesUIFeatureFlagsFragment_company
   SEND_TEST_MESSAGES_ON_LONG_CODES: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGNS_COPY_MESSAGE_ID: false,  // defined in MessagesUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_SUBDOMAINS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   // ENABLE_JOURNEYS_CONCIERGE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AI_COPY_WRITER: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGNS_ESTIMATED_RECIPIENTS_GQL_REFACTOR: false,  // defined in MessagesUIFeatureFlagsFragment_company
   // ONBOARDING_REGION_MGMT: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AC_NUDGE_MODALS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   DISABLE_CREATE_CAMPAIGN_FROM_ROUTE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_EMAIL_SUBJECT_LINE_EXPERIMENTS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGN_RETARGETING_EXPERIMENT: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SUPER_USER_CAMPAIGN_SCHEDULING_WARNING: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SEND_TIME_OPTIMIZATION_TEXT: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SEND_TIME_OPTIMIZATION_EMAIL: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGN_AUDIENCE_FORM_CREATE_SEGMENT: false,  // defined in MessagesUIFeatureFlagsFragment_company
   RELATIVE_TIMEZONE_SENDING_FOR_ALL_REGIONS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AUDIENCES_AI_CAMPAIGNS_DEBUG_MODE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AUDIENCES_AI_CAMPAIGNS_PERFORMANCE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_EMAIL_HEATMAPS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_EMAIL_AUDIENCE_AI_ALPHA: false,  // defined in MessagesUIFeatureFlagsFragment_company
   DISABLE_AI_SEG_MEMBERSHIP_MACRO_ON_UTM_CAMPAIGN: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGNS_DATEPICKER_START_OF_WEEK_SUNDAY: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CMG_DETAIL_PAGE_AI_PRO_WARNING_BANNER: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_CAMPAIGNS_PAGE_STATUS_BANNER: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_ENHANCEMENT_KILL_SWITCH: false,  // defined in MessagesUIFeatureFlagsFragment_company
   DISABLE_SEND_TIME_AI_SELECTED_BY_DEFAULT: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AUDIENCES_AI_BFCM_HANDLING: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SUPERUSER_CAMPAIGN_TAG_DELETE: false,  // defined in MessagesUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_SRS_TESTING_FRAMEWORK: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_DIVERSIFY_PRODUCT_RECOMMENDATIONS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   DISABLE_DIVERSIFY_PRODUCT_REC_CAMPAIGNS: false,  // defined in MessagesUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_SIXTY_TRIAL_SIGNUP: false,  // defined in MlUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_TRIAL_SIGNUP_DELAY_MESSAGE: false,  // defined in MlUIFeatureFlagsFragment_company
   ENABLE_AI_PRO_TRIAL_ENABLEMENT_BFCM_BLACKOUT: false,  // defined in MlUIFeatureFlagsFragment_company
   REFERENCE_UI_TEST: false,  // defined in ReferenceUIFeatureFlagsFragment_company
   SHOW_SAMPLE_UI_RED_HEADER: false,  // defined in ReferenceUIFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_SEGMENTATION_SUBSCRIBERS_IN_SEGMENT: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_PRODUCT_CATALOG_UI: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_REVISED_SEGMENT_CREATION_FLOW: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_ASSISTANT_EXPERIMENT: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_REPORTING_SEGMENTS_UI: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_EXPORTS: false,  // defined in SegmentationUIFeatureFlagsFragment_company
   ALLOW_SENDING_ON_PARENT_DOMAINS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_PRIVACY_EMAIL_ATTRIBUTION_CONTROL: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_TRIGGER_INTEGRATION: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_VIRTUAL_CONTACT_CARD: false,  // defined in SettingsUIFeatureFlagsFragment_company
   DAMPEN_CREATIVE_ADA_VALIDATION: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_PUBLIC_APP_PUBLISH_APP_FLOW: false,  // defined in SettingsUIFeatureFlagsFragment_company
   DISABLE_MESSAGE_EXPLORER_UNIQUE_PHONE_NUMBER_VALIDATION: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_CREATE_SIGNUPS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_UPLOAD: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_JOURNEYS_SAVE_USER_ATTRIBUTE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_CAMPAIGN_EMAIL: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_ATT_STOP_LANGUAGE_FE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SHARED_VS_DEDICATED_EMAIL_DOMAIN_TOGGLE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_SUBDOMAINS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_UNSUBSCRIBE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_LINK_SUBDOMAIN: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_LINK_SUBDOMAIN_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EDIT_COMPANY_TYPE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_ADMIN_SETTINGS_EDIT_COMPANY_URLS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_MESSAGES_SUPER_USER_AUTO_RESPONSE_FIELDS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_CONCIERGE_ADMIN_SETTINGS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ONBOARDING_REGION_MGMT: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_CHANGE_DEFAULT_REGION: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_INTL_BULK_SUBSCRIBER_UPLOAD: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_COGNITO_SFTP_LOGIN: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EMAIL_LIST_CLEANING: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_UNIFIED_COMPANY_SETTINGS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_UPLOAD_EXISTING_SUBSCRIBERS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_UPLOAD_IGNORE_COLUMN: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_PRODUCT_FEEDS_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_PRODUCT_DATA: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_NEW_ADMIN_SETTINGS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_UPLOAD_AUTOFIX_EMPTY_HEADERS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_EDS_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_KEYWORDS_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_COMPANY_INFO_BLOCKS_SETTINGS_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_IDENTITY_AI_MATCH_MACRO: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_IDENTITY_AI_REPORTING: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBER_TOOLS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in SettingsUIFeatureFlagsFragment_company
   DISABLE_MODIFYING_EMAIL_SETTINGS_FROM_ADDRESS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SEGMENT_EXPORTS_TABLE: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_AI_GROW_SETTINGS: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SELF_SERVE_ATTRIBUTION: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_ADMIN_LEGAL_SETTINGS_GRAPHQL: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_RBAC_UI: false,  // defined in SettingsUIFeatureFlagsFragment_company
   ENABLE_SIGNUP_UNIT_PREVIEW: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_SCHEDULING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_EDIT_SIGNUPS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // ENABLE_CREATE_SIGNUPS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CLIENT_USES_COOKIE_FILTERING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_ADVANCED_ANALYTICS_OPTIONS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CDS_SCHEDULING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_TTJ_EDITING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_RENDERER_DISPLAY_RULES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // DAMPEN_CREATIVE_ADA_VALIDATION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_CREATIVE_ADA_VALIDATION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_AGE_GATING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENFORCE_AGE_GATING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CLICK_TO_EDIT_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_TTJ_CODE_MANAGEMENT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_CONSOLIDATION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_GIF_BACKGROUND_EXPERIMENT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_DATE_PREF_COLLECTION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_AB_TEST_MANAGEMENT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_NEW_CREATIVES_ATTRIBUTES_UI: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_COOKIE_TARGETING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_UTM_FILTERING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_REFERRER_FILTERING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_ARCHIVING_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_DISPLAY_RULES_CLIENT_FACING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_DISPLAY_RULES_ONBOARDING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_DISMISS_OPTIONAL_LINK: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_EMAIL_ONLY_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBERS_UI_GRAPHQL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBERS_UI_GRAPHQL_METRICS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_CREATIVE_EDITOR_PERMISSIONS_LIVENATION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUCCESS_PAGE_TIMEOUT_CONTROLS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_TEMPLATE_ACCESS_CA_SPIN_TO_WIN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_TEMPLATE_ACCESS_AU_SPIN_TO_WIN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_TEMPLATE_ACCESS_DE_SPIN_TO_WIN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_TEMPLATE_ACCESS_GB_SPIN_TO_WIN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_TEMPLATE_ACCESS_PT_SPIN_TO_WIN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   UNARCHIVE_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SCHEDULES_REDESIGN: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SCHEDULES_REDESIGN_CALENDAR: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_EU_PREPEND_COUNTRY_CODE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_AB_CREATIVE_SERVICE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   LEGACY_ADMIN_MIGRATION_URL_RULES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_MULTIPLE_CUSTOM_ATTRIBUTES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // ENABLE_BFCM_HOLIDAY_CREATIVE_TEMPLATE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // ONBOARDING_REGION_MGMT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVES_GOOGLE_ANALYTICS_FOUR: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_FULL_WIDTH_BUBBLE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVES_NORTHBEAM_PIXEL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_MOBILE_APP_SIGNUPS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_BACK_IN_STOCK_TEMPLATES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBERS_ARCHIVE_TABLE_GQL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVES_DATALAYER_PUSH: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_COUNTDOWN_TIMER_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_EDIT_CREATIVES_EXPERIMENT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_PRE_ENGAGEMENT_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   CREATIVES_UNLOCK_GEOFILTER: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_EMAIL_ONLY_CREATIVES_REGION_OVERRIDE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_PAST_PURCHASER_RULE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_MYSTERY_OFFER_CREATIVE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_PRODUCT_HIGHLIGHT_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_SOCIAL_PILL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   DATADOG_JAVASCRIPT_SAMPLING_RATE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_RESTRICT_INACTIVE_MESSAGING_SERVICE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   OVERRIDE_RESTRICT_INACTIVE_MESSAGING_SERVICE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_DYNAMIC: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_SHOPIFY_CHECKOUT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_SHOPIFY_CHECKOUT_EMAIL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_SHOPIFY_CHECKOUT_TRANSACTIONAL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_RECHARGE_CHECKOUT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_RECHARGE_CHECKOUT_TRANSACTIONAL: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_AFFILIATE_ENDPOINT: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_PRIVACY: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_TERMS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SPECIFIC_GEO_TARGETING_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_OFFER_DISPLAY_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_PRODUCT_DISPLAY_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   DISABLE_BACKGROUND_IFRAME_SRC_CREATIVE_PREVIEW: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_SPECIFIC_ALREADY_SUB_MESSAGE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_OVERVIEW_PAGE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_TWO_IN_ONE_DYNAMIC_CREATIVE: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SCHEDULES_WORKFLOW: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_AI_CREATIVES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_NEW_CB_THREE_FEATURES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_CREATIVES_DYNAMIC_MANUAL_ENTRY: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   RTL_EDITOR_DYNAMIC_CB_TWO: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBERS_UI_PERFORMANCE_SECTION: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SUBSCRIBERS_UI_TAB_NAV: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_COMPANY_CREATIVE_RULES: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_AI_GROW_REUSABLE_DESIGNS: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_AI_GROW_SELF_SERVE_ONBOARDING: false,  // defined in SubscribersUIFeatureFlagsFragment_company
   ENABLE_SERVICE_CLOUD_CUSTOM_EXTERNAL_ID: false,  // defined in TacticalUIFeatureFlagsFragment_company
   ENABLE_SERVICE_CLOUD_CUSTOM_FIELD_MAPPING: false,  // defined in TacticalUIFeatureFlagsFragment_company
   ENABLE_SERVICE_CLOUD_CUSTOM_ATTRIBUTE_FIELD_MAPPING: false,  // defined in TacticalUIFeatureFlagsFragment_company
   ENABLE_SALESFORCE_SERVICE_CLOUD_SANDBOX: false,  // defined in TacticalUIFeatureFlagsFragment_company
   ENABLE_GAMETIME_CONFIG_UI: false,  // defined in TacticalUIFeatureFlagsFragment_company
   TAG_EDIT_REFACTOR: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_CUSTOM_TAG_EVENTS_SETUP: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_CUSTOM_TAG_ENRICHMENTS_SETUP: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_NO_AUTH_CORS_MERGED_CONFIGS: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_DISPLAY_REQUIRE_TRACKING_CONSENT: false,  // defined in TagUIFeatureFlagsFragment_company
   AEH_ENABLE_SHOPIFY_WEB_PIXEL_SWP_ENDPOINT: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_BLOTOUT_WHITELABEL: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_SERVER_SIDE_TAG_URL: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_CREATIVE_CONFIG_UI: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_LEGACY_CONFIG_UI: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_META_CAPI_TAG_UI_FIELD: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_FINGERPRINT_JS_TAG_UI_FIELD: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_IDENTITY_SCRAPER_EXPERIMENT: false,  // defined in TagUIFeatureFlagsFragment_company
   TAG_UI_ENABLE_DATA_LAYER_TRIGGER: false,  // defined in TagUIFeatureFlagsFragment_company
   TAG_UI_ENABLE_TAG_AI: false,  // defined in TagUIFeatureFlagsFragment_company
   ENABLE_SYNCED_ROWS_GA_ANNOUNCEMENT: false,  // defined in TemplatesUIFeatureFlagsFragment_company
   ENABLE_GENERATIVE_EMAIL_ROW_POC: false,  // defined in TemplatesUIFeatureFlagsFragment_company
   ZENDESK_SIGN_IN_BEFORE_REDIRECT: false,  // defined in AuthCoreLibFeatureFlagsFragment_company
   // ENABLE_CAMPAIGNS_COPY_MESSAGE_ID: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   // ENABLE_CAMPAIGN_EMAIL: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   // ENABLE_EMAIL_SUBJECT_LINE_EXPERIMENTS: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   // ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   DISABLE_AUDIENCES_AI_BY_DEFAULT: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   ENABLE_RBM_ALPHA: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   ENABLE_RECURRING_CAMPAIGNS: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   ENABLE_PUSH_CAMPAIGNS: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   DISABLE_NON_RECURRING_CAMPAIGN_EMAIL_FEATURES: false,  // defined in CampaignsCoreLibFeatureFlagsFragment_company
   ENABLE_COUPON_SET_DISTRIBUTION_UI: false,  // defined in CouponsLibFeatureFlagsFragment_company
   ENABLE_OFFER_VALUE_INPUT: false,  // defined in CouponsLibFeatureFlagsFragment_company
   ENABLE_AUTOMATED_MESSAGING: false,  // defined in CouponsLibFeatureFlagsFragment_company
   ENABLE_AIJ_OFFER_VALUE_INPUT: false,  // defined in CouponsLibFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in CRMLibFeatureFlagsFragment_company
   ENABLE_RESERVED_ATTRIBUTES_TABLE: false,  // defined in CRMLibFeatureFlagsFragment_company
   ENABLE_EDS_PRODUCT_CATALOG: false,  // defined in EDSLibFeatureFlagsFragment_company
   ENABLE_EDS_ERROR_REPORTING: false,  // defined in EDSLibFeatureFlagsFragment_company
   ENABLE_EDS_SNOWFLAKE_DATA_SOURCE: false,  // defined in EDSLibFeatureFlagsFragment_company
   ENABLE_KLAVIYO_RICH_SETTINGS: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_OMETRIA_RICH_SETTINGS: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   // ENABLE_NEW_AFTERSHIP_SETUP: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_OLO_SYNC_PRODUCTS: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_KLAVIYO_OPT_IN_SYNC: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ALLOW_BIG_COMMERCE_PRODUCT_SYNC: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_ZENDESK_OAUTH: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_GORGIAS_OAUTH: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_HUBSPOT_OAUTH: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_PUNCHH_ATTENTIVE_LOYALTY_SIGNUP: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_USERID_SELECT_FEATURE_FIELD: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   ENABLE_SFMC_POSTBACK_ENHANCEMENTS: false,  // defined in IntegrationsCommonLibFeatureFlagsFragment_company
   // ENABLE_ATT_STOP_LANGUAGE_FE: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   ENABLE_CONCIERGE_TRY_ADDING_A_QUESTION_BANNER: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   ENABLE_LIMIT_IMAGE_UPLOAD_SIZE_IN_CAMPAIGNS: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   // ENABLE_CONCIERGE_COMPANY_PROMOTIONS: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   ENABLE_CLOUDINARY_MMS_CREATION: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   ENABLE_MMS_FOR_CA_SHORT_CODES_ON_MESSAGE_COMPOSER: false,  // defined in MessagingCoreLibFeatureFlagsFragment_company
   // ENABLE_TRIGGERED_EMAIL: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   // ENABLE_PRODUCT_DATA: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BY_KLAVIYO_EMAIL_EVENT: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_MPARTICLE_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SMILE_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_PUNCHH_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_RECHARGE_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENTATION_WELL_KNOWN_PROP_FILTER: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENTATION_CUSTOM_EVENT_FILTERING: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_ON_LANGUAGE: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_YOTPO_ATTRIBUTES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_ON_REGION: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BY_MESSAGE_WITH_KEYWORD: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_CUSTOM_ATTRIBUTE_DATE_AND_NUMBER_TYPE_CREATION: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BUILDER_DEBUG: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_RECHARGE_ORDER_EVENTS: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_CUSTOM_ATTRIBUTE_IN_THE_NEXT_COMPARATOR: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BY_JOURNEY_MESSAGE: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENTATION_PARTIAL_DATES: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_LOCATION_ATTRIBUTES_INTL: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENTATION_NEW_TIME_COMPARATORS: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BUILDER_OPERATOR_UI: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_BAZAARVOICE: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_DATA_DIALOG_SELECT_ALL_UI: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   // ENABLE_SEGMENT_ASSISTANT_EXPERIMENT: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   USE_WITHIN_THE_LAST_DEFAULT_IN_SEGMENT_BUILDER: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   // ENABLE_EMAIL_ONLY_COMPANY: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_CUSTOM_ATTRIBUTES_LONG_TYPE: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   HIDE_SEGMENT_BUILDER_PREFERENCES_CONDITIONS: false,  // defined in TargetingCommonLibFeatureFlagsFragment_company
   ENABLE_RSP_PRODUCT_RECS_SUBSCRIBER_FILTER: false,  // defined in EmailCoreLibFeatureFlagsFragment_company
   DISABLE_MESSAGE_VALIDATION: false,  // defined in EmailCoreLibFeatureFlagsFragment_company
   DISABLE_EMAIL_SIZE_VALIDATION: false,  // defined in EmailCoreLibFeatureFlagsFragment_company
   ENABLE_EMAIL_SYNCED_ROWS: false,  // defined in EmailCoreLibFeatureFlagsFragment_company
   ENABLE_SHARED_EMAIL_TEMPLATES: false,  // defined in EmailCoreLibFeatureFlagsFragment_company
   ENABLE_BEE_DOWNTIME_BANNER: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_ATT_STOP_LANGUAGE: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   // ENABLE_EMAIL_SYNCED_ROWS: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   // ENABLE_GENERATIVE_EMAIL_ROW_POC: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_SMS_MEDIA_DYNAMIC_IMAGE_MACRO_SELECTION: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_GIF_CREATOR: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_INDIVIDUAL_LINK_PARAMS: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_EMAIL_COPY_RECIPIENTS_JOURNEYS: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_EMAIL_COPY_RECIPIENTS_CAMPAIGNS: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_MAX_HEIGHT_DYNAMIC_IMAGES: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_OPEN_IN_BROWSER_SHORT_LINKS: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_ORIGINAL_PRICE: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_RCS_MEDIA_MESSAGE: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   CF_LIQUID_DISPLAY_SEGMENT_MEMBER: false,  // defined in MessageCreationCoreLibFeatureFlagsFragment_company
   ENABLE_SCALE_AI_IMAGES: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_TEST_MAGIC_IMAGE_CTA: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_BRAND_VOICE: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   // ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE_BRAND_VOICE: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   // ENABLE_CA_SHUT_OFF_SWITCH: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_GEN_AI_TEXT_EXPERIMENT: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_UNIFIED_AUTO_JOURNEY: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   // ENABLE_CAMPAIGN_EMAIL: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_PRO_BRAND_VOICE_DEV: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_BV_AI_PRO_ASSISTANTS_EXPERIMENT: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   // ENABLE_AI_JOURNEYS_PERFORMANCE_UI: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_MESSAGE_CONTENT_MODERATION: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_ATTRIBUTION: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_BRAND_VOICE_SETTINGS_TONE_KEYWORDS: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_TRAFFIC_SPLIT: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_KEY_METRICS: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_NORMALIZED_LIFT: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_JOURNEYS_PERFORMANCE_FF_STATUS_BANNER: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_NEW_OPEN_AI_MODEL_FOR_COPY_ASSISTANT: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   EXCLUSION_WORDS_UI_ENABLED: false,  // defined in MLCommonLibFeatureFlagsFragment_company
   ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI: false,  // defined in BrandStylesLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_FEEDS_PRICE_FILTER: false,  // defined in ProductsLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_FEEDS_TAG_FILTER: false,  // defined in ProductsLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_FEEDS_INVENTORY_FILTER: false,  // defined in ProductsLibFeatureFlagsFragment_company
   ENABLE_PRODUCT_FEEDS_BUILDER_SELECT_ALL: false,  // defined in ProductsLibFeatureFlagsFragment_company
   ENABLE_AUDIENCES_AI_EMAIL_BETA: false,  // defined in AudiencesAiLibFeatureFlagsFragment_company
   ENABLE_AUDIENCES_AI_PRODUCT_AFFINITY: false,  // defined in AudiencesAiLibFeatureFlagsFragment_company
   ENABLE_AI_TRIAL_UPSELL: false,  // defined in AudiencesAiLibFeatureFlagsFragment_company
   // ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: false,  // defined in AudiencesAiLibFeatureFlagsFragment_company
   ENABLE_AI_PRO_EMAIL_ENABLEMENT: false,  // defined in AudiencesAiLibFeatureFlagsFragment_company
   // ENABLE_GIF_CREATOR: false,  // defined in RcsMessageComposerLibFeatureFlagsFragment_company
};

